import { RedirectRequest, SilentRequest } from "@azure/msal-browser"

//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2CAAD_CLIENT_ID || "",
    authority:
      `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}` ||
      "",
    knownAuthorities: [process.env.REACT_APP_B2CAAD_AUTHORITY || ""],
    redirectUri: "/",
    //postLogoutRedirectUri: "/logout",
    postLogoutRedirectUri: "/",
    scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
  },
}

export const msalConfigJA = {
  auth: {
    clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}` || "",
    authority:
      `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}` ||
      "",
    knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}` || ""],
    redirectUri: "/",
    //postLogoutRedirectUri: "/logout",
    postLogoutRedirectUri: "/?authExperience=ja",
    scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
  },
}

export const msalConfigSvsVision = {
  auth: {
    clientId: `${process.env.REACT_APP_B2CAAD_CLIENT_ID}` || "",
    authority:
      `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}` ||
      "",
    knownAuthorities: [`${process.env.REACT_APP_B2CAAD_AUTHORITY}` || ""],
    redirectUri: "/",
    //postLogoutRedirectUri: "/logout",
    postLogoutRedirectUri: "/?authExperience=svsvision",
    scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
  },
}

export const b2cPolicies = {
  names: {
    signInLocal: "SignInLocal",
    signInSvs: "SignInSvs",
    signInJA: "SignInJA",
  },
  authorities: {
    signInSvs: {
      clientId: process.env.REACT_APP_B2CAAD_CLIENT_ID,
      authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}`,
      knownAuthorities: [process.env.REACT_APP_B2CAAD_AUTHORITY],
      redirectUri: "/",
      postLogoutRedirectUri: "/logout",
      scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
    },
    signInLocal: {
      clientId: process.env.REACT_APP_B2CAAD_CLIENT_ID,
      authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}`,
      knownAuthorities: [process.env.REACT_APP_B2CAAD_AUTHORITY],
      redirectUri: "/",
      postLogoutRedirectUri: "/logout",
      scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
    },
    signInJA: {
      clientId: process.env.REACT_APP_B2CAAD_CLIENT_ID,
      authority: `https://${process.env.REACT_APP_B2CAAD_AUTHORITY}/${process.env.REACT_APP_B2CAAD_TENANT}/${process.env.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}`,
      knownAuthorities: [process.env.REACT_APP_B2CAAD_AUTHORITY],
      redirectUri: "/",
      postLogoutRedirectUri: "/logout",
      scopes: ["openid", process.env.REACT_APP_B2CAAD_CLIENT_ID],
    },
  },
}

export function getB2cConfig(
  policyName: string,
  authExperience = "default"
): RedirectRequest {
  const names = b2cPolicies.names
  let policy = null

  switch (policyName) {
    case names.signInLocal:
      policy = b2cPolicies.authorities.signInLocal
      break
    case names.signInSvs:
      policy = b2cPolicies.authorities.signInSvs
      break
    case names.signInJA:
      policy = b2cPolicies.authorities.signInJA
      break
    default: {
      if (authExperience === "svsvision") {
        policy = b2cPolicies.authorities.signInSvs
      } else if (authExperience === "ja") {
        policy = b2cPolicies.authorities.signInJA
      } else {
        policy = b2cPolicies.authorities.signInLocal
      }
      policy.postLogoutRedirectUri = `/?authExperience=${authExperience}`
    }
  }

  const untypedResult = policy

  return untypedResult as RedirectRequest
}

export function getB2cSilentRequest(
  policyName: string,
  authExperience = "default"
): SilentRequest {
  const names = b2cPolicies.names
  let policy = null

  switch (policyName) {
    case names.signInLocal:
      policy = b2cPolicies.authorities.signInLocal
      break
    case names.signInSvs:
      policy = b2cPolicies.authorities.signInSvs
      break
    case names.signInJA:
      policy = b2cPolicies.authorities.signInJA
      break
    default: {
      if (authExperience === "svsvision") {
        policy = b2cPolicies.authorities.signInSvs
      } else if (authExperience === "ja") {
        policy = b2cPolicies.authorities.signInJA
      } else {
        policy = b2cPolicies.authorities.signInLocal
      }
      policy.postLogoutRedirectUri = `/?authExperience=${authExperience}`
    }
  }

  const untypedResult = policy

  return untypedResult as SilentRequest
}

export function getB2cConfigExperience(authExperience = "default") {
  if (authExperience === "svsvision") {
    return msalConfigSvsVision
  } else if (authExperience === "ja") {
    return msalConfigJA
  } else {
    return msalConfig
  }
}
