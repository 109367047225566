import { msalConfig } from "./msalConfig"
import { PublicClientApplication } from "@azure/msal-browser"

export default {
  // called when the user attempts to log in
  login: () => {
    // localStorage.setItem("SessionId", "1082408")
    // window.location.reload()
    // accept all username/password combinations
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("SessionId")
    const instance = new PublicClientApplication(msalConfig)
    const logoutRequest = {
      account: instance.getActiveAccount(),
    }
    return instance.logoutRedirect(logoutRequest)
    //return Promise.resolve()
  },
  // called when the API returns an error
  checkError: (status: number) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("SessionId")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("SessionId")
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}
