/* eslint-disable react/display-name */
import React from "react"
import { TableHead, TableRow, makeStyles } from "@material-ui/core"
import { TableCellStyle, TextFieldUI } from "../../utils/styled"
import { useConsumerFilter } from "./useConsumerFilter"
import { Filter } from "react-admin"
import CustomReferenceInput from "../CustomReferenceInput"
import CustomSelectInput from "../CustomSelectInput"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"

const styleCombobox = makeStyles({
  combobox: {
    backgroundColor: "inherit",
    marginBottom: "18px",
    "& .MuiInputBase-root.MuiFilledInput-root": {
      backgroundColor: "inherit",
    },
    "& .MuiFormLabel-root.MuiInputLabel-root ": {
      display: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "inherit",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 5px)",
    },
  },
})

const FilterInputRender = React.memo((props: any) => {
  const { headCell, changeValueFilter, handlerOutFocus } = props
  const combobox = styleCombobox()

  let render = <></>

  switch (headCell.id) {
    case "locationID":
      render = (
        <Filter>
          <CustomReferenceInput
            label={headCell.label}
            source="storeID"
            reference="stores"
            className={combobox.combobox}
            alwaysOn
            allowEmpty={true}
            emptyText="all"
            perPage={0}
          >
            <CustomSelectInput optionText="locationID3" />
          </CustomReferenceInput>
        </Filter>
      )
      break

    // case "haS_ENGAGED":
    //   render = (
    //     <Filter>
    //       <CheckboxUI
    //         id={`standard-search-${headCell.id}`}
    //         autoFocus
    //         name={headCell.id.toString()}
    //         defaultValue={headCell.valueSeach}
    //         onChange={changeValueFilter}
    //       />
    //     </Filter>
    //   )
    //   break
    case "storeName":
      render = (
        <Filter>
          <CustomReferenceInput
            label={headCell.label}
            className={combobox.combobox}
            source="storeID"
            reference="stores"
            alwaysOn
            allowEmpty={true}
            emptyText="all"
            perPage={0}
          >
            <CustomSelectInput optionText="storeName" />
          </CustomReferenceInput>
        </Filter>
      )
      break
    case "age":
    case "patientHomeNum":
    case "patientCellNum":
    case "patientZip":
    case "latitude":
    case "longitude":
      render = (
        <TextFieldUI
          id={`standard-search-${headCell.id}`}
          autoFocus
          placeholder={headCell.label}
          name={headCell.id.toString()}
          defaultValue={headCell.valueSeach}
          onChange={changeValueFilter}
          onBlur={handlerOutFocus}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon style={{ fontSize: "1.2rem" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )
      break

    default:
      render = (
        <>
          <TextFieldUI
            id={`standard-search-${headCell.id}`}
            autoFocus
            placeholder={headCell.label}
            name={headCell.id.toString()}
            defaultValue={headCell.valueSeach}
            onChange={changeValueFilter}
            type="search"
            onBlur={handlerOutFocus}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon style={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )
  }

  return <>{render}</>
})

export const ConsumerFilter = () => {
  const {
    headCells,
    handlerOutFocus,
    handleClickFilter,
    changeValueFilter,
    handleClickAway,
  } = useConsumerFilter()

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCellStyle
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? "none" : "default"}
            >
              {headCell.isFilter || headCell.valueSeach !== "" ? (
                <FilterInputRender
                  headCell={headCell}
                  handlerOutFocus={handlerOutFocus}
                  changeValueFilter={changeValueFilter}
                />
              ) : (
                <div
                  onClick={() => handleClickFilter(headCell)}
                  className="label-headcell"
                >
                  {headCell.label}
                </div>
              )}
            </TableCellStyle>
          ))}
        </TableRow>
      </TableHead>
    </>
  )
}
