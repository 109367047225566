import * as React from "react"
import Box from "@material-ui/core/Box"
import { useSelector } from "react-redux"
import { MenuItemLink, getResources } from "react-admin"
import DefaultIcon from "@material-ui/icons/ViewList"
import { makeStyles } from "@material-ui/core/styles"

export interface IMyMenuProps {
  onMenuClick?: () => void
  logout?: any
}

const useStyles = makeStyles({
  box: {
    width: "80%",
    paddingLeft: "10px",
    height: "100%",
  },
  link: {
    color: "white",
    fontSize: "15px",
    textTransform: "capitalize",
  },
  active: {
    borderBottom: "2px solid white",
  },
  lineH: {
    borderLeft: "1px solid #54A9A3",
    width: "1px",
    height: "25px",
    marginLeft: "20px",
  },
})

export const MyMenu: React.FC<IMyMenuProps> = (props: IMyMenuProps) => {
  const classes = useStyles()

  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)

  return (
    <>
      <div className={classes.lineH}></div>
      <Box display="flex" className={classes.box}>
        {resources.map(resource => (
          <MenuItemLink
            key={resource.name}
            activeClassName={classes.active}
            className={classes.link}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            sidebarIsOpen={open}
          />
        ))}
        {/* {isXSmall && logout} */}
      </Box>
    </>
  )
}
