/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from "react"
import { connect, useDispatch } from "react-redux"
import Button from "@material-ui/core/Button"
import { refreshView, showNotification, useNotify } from "react-admin"
// import { push } from "react-router-redux"
import dataProvider from "../dataProvider"
import AssignmentReturnedSharpIcon from "@material-ui/icons/AssignmentReturnedSharp"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

interface IAssignButtonProps {
  record?: any
  label?: string
}

const AssignButton: React.FC<IAssignButtonProps> = (
  props: IAssignButtonProps
) => {
  const dispatch = useDispatch()
  const notify = useNotify()

  const assignConsumer = async () => {
    const { record } = props
    try {
      const result: any = await dataProvider.assignConsumer(record.id)
      if (result.status === 200) {
        notify("assigned consumers successfully")
      } else {
        notify(result.body, "error")
      }
      dispatch(refreshView())
    } catch (ex) {
      notify(ex, "error")
    }
  }

  return (
    <Button
      style={{ textTransform: "none", color: "#0F877E", fontWeight: "bold" }}
      startIcon={
        <ArrowForwardIcon
          style={{
            fontSize: 15,
            color: "#fff",
            backgroundColor: "#0F877E",
            borderRadius: "50%",
            padding: "3px",
          }}
        />
      }
      onClick={assignConsumer}
      href=""
    >
      Assign
    </Button>
  )
}

// AssignButton.propTypes = {
//   // push: PropTypes.func,
//   record: PropTypes.object,
//   showNotification: PropTypes.func,
// }

// export default connect(null, {
//   showNotification,
//   // push,
// })(AssignButton)

export default AssignButton
