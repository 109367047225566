export const getAge = (birthDay: string): string => {
  try {
    const birthDate = new Date(birthDay)
    if (Object.prototype.toString.call(birthDate) !== "[object Date]") {
      return ""
    }
    if (isNaN(birthDate.getTime())) {
      return ""
    }

    const ageDifMs = Date.now() - birthDate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    return `${ageDate.getUTCFullYear() - 1970}`
  } catch (ex) {
    return ""
  }
}

export const formatDate = (birthDay: string): string => {
  try {
    const birthDate = new Date(birthDay)
    if (Object.prototype.toString.call(birthDate) !== "[object Date]") {
      return ""
    }
    if (isNaN(birthDate.getTime())) {
      return ""
    }

    return `${
      birthDate.getMonth() + 1
    }/${birthDate.getDate()}/${birthDate.getFullYear()}`
  } catch (ex) {
    return ""
  }
}
