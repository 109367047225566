import { styled } from "@material-ui/core/styles"
import {
  TextInput,
  NumberInput,
  // ReferenceInput,
  SimpleForm,
  // AutocompleteInput,
  Edit,
  Toolbar,
  SaveButton,
  TopToolbar,
  // SelectInput,
  // AutocompleteArrayInput,
  // FormTab,
  // TabbedForm,
} from "react-admin"
import {
  Button,
  TableContainer,
  TextField,
  Paper,
  TableCell,
} from "@material-ui/core"
import CustomReferenceInput from "../components/CustomReferenceInput"
import { CSSProperties } from "react"
export const TwoInput = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
})
export const SimpleFormWidth = styled(SimpleForm)({
  maxWidth: "768px",
  margin: "0 auto",
})
export const TextInputCss = styled(TextInput)({
  paddingRight: "10px",
})

export const TextInputFullCss = styled(TextInput)({
  width: "calc(100% - 10px)",
})

export const CustomReferenceInputCss = styled(CustomReferenceInput)({
  width: "calc(100% - 10px)",
})
export const ToolbarStyle = styled(Toolbar)({
  backgroundColor: "unset",
})

export const CellAssign = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "#0F877E",
  cursor: "pointer",
})

export const AssignIcon = styled("div")({
  backgroundColor: "#0F877E",
  borderRadius: "50%",
  width: "25px",
  height: "25px",
  marginRight: "10px",
})

export const ToolBar = styled("div")({
  padding: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  color: "#0F877E",
})

export const ImportButtonToolBar = styled(Button)({
  border: "1px solid #0F877E",
  marginRight: "10px",
  padding: "4px 10px",
  color: "#FFFFFF",
  background: "#0F877E",
  textTransform: "none",
  fontSize: "0.8em",
  "&:hover": {
    color: "#FFFFFF",
    background: "#0F877E",
  },
})

export const ExportButtonToolBar = styled(Button)({
  border: "1px solid #0F877E",
  marginRight: "10px",
  padding: "4px 10px",
  color: "#FFFFFF",
  background: "#000",
  textTransform: "none",
  fontSize: "0.8em",
  "&:hover": {
    color: "#FFFFFF",
    background: "#000",
  },
})

export const ButtonToolBar = styled(Button)({
  border: "1px solid #0F877E",
  marginRight: "10px",
  color: "#0F877E",
})

export const TableContainerStyle = styled(TableContainer)({
  margin: "0 auto",
  width: "calc(100% - 30px)",
})

export const TextFieldUI = styled(TextField)({
  width: "inherit",
  "& .MuiInputLabel-formControl": {
    padding: "0 20px",
    fontSize: "15px",
  },
  "& input": {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "15px",
    width: "auto",
    "&::-webkit-input-placeholder": {
      fontSize: "14px",
    },
  },
  "& .MuiInput-underline": {
    "&:before, &:after": {
      bottom: "5px",
    },
  },
  "& .MuiIconButton-label": {
    paddingBottom: "5px",
  },
  "& .MuiIconButton-root:hover": {
    backgroundColor: "unset",
  },
  "& button.MuiIconButton-root": {
    padding: "10px 0",
  },
})

export const PaperStyle = styled(Paper)({
  width: "calc(100vw - 200px)",
  margin: "0 auto",
  "& .MuiTableContainer-root": {
    width: "calc(100% - 60px)",
    overflow: "auto",
    height: "auto",
    maxHeight: "70vh",
  },
  "& .pagination": {
    "& .MuiTablePagination-toolbar": {
      "& p:nth-child(2)": {
        display: "none",
      },
    },
    "& .MuiTablePagination-selectRoot": {
      display: "none",
    },
  },
})

export const TextInputFilterCss = styled(TextInput)({
  background: "transparent",
  "& div": {
    background: "transparent !important",
  },
  "& div:hover": {
    background: "transparent",
  },
})

export const TextInputFilterUI = styled(TextInput)({
  width: "100%",
  "& .MuiInputLabel-formControl": {
    display: "none",
  },
  "& input": {
    minWidth: "45px",
    paddingTop: "22px",
    paddingBottom: "15px",
    fontSize: "15px",
    "&::-webkit-input-placeholder": {
      fontSize: "15px",
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "white",
  },
  "& .MuiInput-underline": {
    "&:before, &:after": {
      bottom: "10px",
    },
  },
  "& .MuiIconButton-label": {
    paddingBottom: "5px",
  },
  "& .MuiIconButton-root:hover": {
    backgroundColor: "unset",
  },
  "& button.MuiIconButton-root": {
    padding: "10px 0",
  },
})

export const GetFilterInput = (
  component: typeof TextInput | typeof NumberInput
) =>
  styled(component)({
    width: "100%",
    "& .MuiInputLabel-formControl": {
      display: "none",
    },
    "& input": {
      minWidth: "45px",
      paddingTop: "22px",
      paddingBottom: "15px",
      fontSize: "15px",
      "&::-webkit-input-placeholder": {
        fontSize: "15px",
      },
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },
    "& .MuiInput-underline": {
      "&:before, &:after": {
        bottom: "10px",
      },
    },
    "& .MuiIconButton-label": {
      paddingBottom: "5px",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "unset",
    },
    "& button.MuiIconButton-root": {
      padding: "10px 0",
    },
  })

export const TableCellStyle = styled(TableCell)({
  cursor: "pointer",
  width: "120px",
  height: "50px",
  verticalAlign: "middle",
  padding: "10px 5px",
  "&:first-child": {
    width: "100px",
  },
  "&:last-child": {
    width: "80px",
  },
  "&:nth-child(2), &:nth-child(3), &:nth-child(4)": {
    width: "150px",
  },
  "& .label-headcell": {
    width: "inherit",
  },
})
