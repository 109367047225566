import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { RecordMap, useListContext, linkToRecord } from "react-admin"

import { IDataConsumerList } from "./consumers.model"

export const useConsumerList = () => {
  const consumerContext = useListContext()
  const history = useHistory()
  const { ids, data } = consumerContext

  const dataMapping = useCallback(
    (data: RecordMap<Record<string, string>>): IDataConsumerList[] => {
      return ids.map(id => {
        return { id: id.toString(), ...data[id] }
      })
    },
    [ids]
  )

  const redirectDetails = useCallback(
    (id: any) => {
      history.push(linkToRecord(consumerContext.basePath, id))
    },
    [consumerContext.basePath, history]
  )

  return {
    ids,
    consumerContext,
    dataConsumer: useMemo(() => dataMapping(data), [data, dataMapping]),
    redirectDetails,
  }
}
