import React, { Component } from "react"
import { connect } from "react-redux"
import { userLogin } from "react-admin"
// import { LoginComponentProps } from "ra-core/lib/types"
import { RouteComponentProps } from "react-router-dom"
// import { MuiThemeProvider } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Title } from "react-admin"
import dataProvider from "./dataProvider"
import Constants from "./constants"
import axios from "axios"
import Authwall from "./authwall"

type MyLoginPageState = { status: boolean }
class MyLoginPage extends Component<RouteComponentProps, MyLoginPageState> {
  constructor(props: RouteComponentProps) {
    super(props)
    this.state = { status: false }
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionID = urlParams.get("sessionid")
    if (sessionID) {
      dataProvider.checkAuth(sessionID).then(status => {
        if (status) {
          localStorage.setItem("SessionId", sessionID)
          window.location.href = "/"
        } else {
          if (process.env.REACT_APP_LOGIN_URL) {
            window.location.href = process.env.REACT_APP_LOGIN_URL
            return
          }
          this.setState({ status: true })
          console.log(this.state.status)
        }
      })
      return
    }
    if (process.env.REACT_APP_LOGIN_URL) {
      window.location.href = process.env.REACT_APP_LOGIN_URL
      return
    }
    this.setState({ status: true })
  }
  render() {
    return (
      <Authwall>
        <Card>
          <Title title="Login" />
          {!this.state.status && <CardContent>Checking login...</CardContent>}
          {this.state.status && <CardContent>Missing sessionid.</CardContent>}
        </Card>
      </Authwall>
    )
  }
}

export default connect(undefined, { userLogin })(MyLoginPage)
