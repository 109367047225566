import React, { useEffect } from "react"
import { useCallback } from "react"
import { useListContext } from "react-admin"

import { HeadCell, DataType } from "./consumers.model"

export const defaultfilter: HeadCell[] = [
  {
    id: "id",
    sort: true,
    disablePadding: true,
    label: "ID",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientFirstName",
    sort: false,
    disablePadding: false,
    label: "First Name",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientLastName",
    sort: false,
    disablePadding: false,
    label: "Last Name",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientEmail",
    sort: false,
    disablePadding: false,
    label: "Email",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "age",
    sort: false,
    disablePadding: false,
    label: "Age",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientGender",
    sort: false,
    disablePadding: false,
    label: "Gender",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientHomeNum",
    sort: false,
    disablePadding: false,
    label: "Home Number",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientCellNum",
    sort: false,
    disablePadding: false,
    label: "Mobile Number",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientAddress1",
    sort: false,
    disablePadding: false,
    label: "Street address",
    isDisabledFilter: false,
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientState",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "State",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientCity",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "City",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "patientZip",
    sort: false,
    disablePadding: false,
    label: "Zip",
    isFilter: false,
    isDisabledFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "latitude",
    sort: false,
    disablePadding: false,
    label: "Latitude",
    isDisabledFilter: false,
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "longitude",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "Longitude",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "locationID",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "Location ID",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "storeName",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "Store Name",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "sourceFlag",
    sort: false,
    disablePadding: false,
    isDisabledFilter: false,
    label: "Source Flag",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
  },
  {
    id: "haS_ENGAGED",
    sort: false,
    disablePadding: false,
    isDisabledFilter: true,
    label: "Has Engaged",
    isFilter: false,
    valueSeach: "",
    isNotRender: false,
    dataType: DataType.Boolean,
  },
  {
    id: "",
    sort: false,
    disablePadding: false,
    label: "Edit",
    isFilter: false,
    valueSeach: "",
    isDisabledFilter: true,
    isNotRender: true,
  },
  {
    id: "",
    sort: false,
    disablePadding: false,
    label: "Verify",
    isFilter: false,
    valueSeach: "",
    isDisabledFilter: true,
    isNotRender: true,
  },
]

export const useConsumerFilter = () => {
  const { setFilters, displayedFilters, filterValues } = useListContext()

  const [headCells, setHeadCells] = React.useState<HeadCell[]>(defaultfilter)

  const changeValueFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target

      setFilters &&
        setFilters({ ...filterValues, [name]: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  const handlerOutFocus = useCallback((event: any) => {
    const { value, name } = event.target

    if (value === "") {
      setHeadCells(headCells => {
        const updateHeadCells = headCells.map(headCell => ({
          ...headCell,
          isFilter: headCell.id == name ? false : headCell.isFilter,
        }))
        return updateHeadCells
      })
    }
  }, [])

  const handleClickFilter = useCallback(
    (headSelect: HeadCell) => {
      if (headSelect.isDisabledFilter) {
        return
      }

      const heads: HeadCell[] = [...headCells].map((head: HeadCell) => {
        if (head.id === headSelect.id) {
          head.isFilter = !head.isFilter
        }
        return head
      })
      setHeadCells(heads)
    },
    [headCells]
  )

  const handleClickAway = useCallback(
    (data: HeadCell) => {
      if (data.isDisabledFilter || !data.isFilter) {
        return
      }
      const heads: any = [...headCells].map((head: HeadCell) => {
        if (head.id === data.id.toString() && !data.valueSeach) {
          head.isFilter = !head.isFilter
        }
        return head
      })
      setHeadCells(heads)
    },
    [headCells]
  )

  useEffect(() => {
    setHeadCells(headCells => {
      const updateHeadCells = headCells.map(headCell => {
        let _valueSeach = ""
        if (["locationID", "storeName"].includes(headCell.id.toString())) {
          _valueSeach =
            filterValues["storeID"] && filterValues["storeID"] !== "all"
              ? "storeID"
              : ""
        } else {
          _valueSeach = filterValues[headCell.id] || ""
        }
        return {
          ...headCell,
          valueSeach: _valueSeach,
        }
      })
      return updateHeadCells
    })
  }, [filterValues])

  return {
    headCells,
    handlerOutFocus,
    changeValueFilter,
    handleClickAway,
    handleClickFilter,
    setFilters,
  }
}
