import { useCallback, useMemo } from "react"
import { useHistory } from "react-router-dom"
import {
  Identifier,
  RecordMap,
  useListContext,
  linkToRecord,
} from "react-admin"

import { IDataStoreList } from "./store.model"

export const useStoresList = () => {
  const storeContext = useListContext()
  const history = useHistory()
  const { ids, data } = storeContext

  const dataMapping = useCallback(
    (data: RecordMap<Record<string, string>>): IDataStoreList[] => {
      return ids.map(id => {
        return { id: id.toString(), ...data[id] }
      })
    },
    [ids]
  )

  const redirectDetails = useCallback(
    (id: any) => {
      history.push(linkToRecord(storeContext.basePath, id))
    },
    [storeContext.basePath, history]
  )

  return {
    ids,
    storeContext,
    dataStores: useMemo(() => dataMapping(data), [data, dataMapping]),
    redirectDetails,
  }
}
