import React, { useState, useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import {
  Notification,
  Sidebar,
  AppBar,
  setSidebarVisibility,
  LayoutProps,
  Menu,
} from "react-admin"
import { MyAppBar } from "./MyAppBar"
import { createMuiTheme } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  header: {
    display: "flex",
  },
  contentWithSidebar: {
    margin: "0 100px",
    display: "flex",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: "4em",
    paddingLeft: 5,
  },
}))

const baseTheme = createMuiTheme()

export const MyLayout: React.FunctionComponent<LayoutProps> = (
  props: React.PropsWithChildren<LayoutProps>
) => {
  const { children, title, logout } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)

  useEffect(() => {
    dispatch(setSidebarVisibility(true))
  }, [dispatch])

  return (
    <ThemeProvider theme={baseTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <div className={classes.header}>
            <MyAppBar logout={logout as JSX.Element} title={title as string} />
          </div>
          <main className={classes.contentWithSidebar}>
            <div className={classes.content}>{children}</div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  )
}
