/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
  /**
   * Represents the current user key.
   */
  get currUserKey() {
    return "cusr"
  },
  /**
   * Represents the Session ID.
   */
  get sessionID() {
    return "SessionID"
  },
  get userRole() {
    return "userRole"
  },
  get familyName() {
    return "family_name"
  },
  get locations() {
    return "locations"
  },
  get currentLocKeys() {
    return "currentLocKeys"
  },
  get locationAware() {
    return "locationAware"
  },
  get allLocations() {
    return "allLocations"
  },
  get encryptedElements() {
    return "encElements"
  },
  /**
   * Represents the Session ID key.
   */
  get sessionIDKey() {
    return "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"
  },

  get key() {
    return "key"
  },
  get applicationId() {
    return 3
  },
  /*
  Element Names
  */
  get dashboardMenu() {
    return "Dv4Communications"
  },
  get reportsMenu() {
    return "Dv4Reports"
  },
  get new2WayMenu() {
    return "Dv4Chat"
  },
  get registrationFormMenu() {
    return "Dv4PatientForms"
  },
  get onlineBookingSetupMenu() {
    return "Dv4OBS"
  },
  get insuranceTypeMenu() {
    return "InsuranceTypeMenu"
  },
  get appointmentTypeMenu() {
    return "AppointmentTypeMenu"
  },
  get patientInsuranceMenu() {
    return "PatientInsuranceMenu"
  },
  get generalMenu() {
    return "GeneralMenu"
  },
  get scheduleMenu() {
    return "ScheduleMenu"
  },
  get recallOverviewMenu() {
    return "Dv4RecallOverview"
  },
  get recallDetailMenu() {
    return "Dv4RecallDetailMenu"
  },
  get remindersMenu() {
    return "Dv4RemindersMenu"
  },
  get appointmentsMenu() {
    return "Dv4AppointmentsMenu"
  },
  get cancellationMenu() {
    return "Dv4CancellationMenu"
  },
  get ordersMenu() {
    return "Dv4OrdersMenu"
  },
  get visitsMenu() {
    return "Dv4VisitsMenu"
  },
  get purchaseRecaptureMenu() {
    return "Dv4PurchaseRecaptureMenu"
  },
}

// Lock object to prevent modification (true static).
Object.freeze(Constants)

export default Constants
