import React from "react"
import { Edit, SaveButton } from "react-admin"
import {
  TextInputCss,
  SimpleFormWidth,
  TwoInput,
  ToolbarStyle,
} from "../../utils/styled"
import { required } from "../../utils/validate"

const StoreTitle = (record: any) => {
  return (
    <span>
      Store {record && record.record ? `"${record.record.storeName}"` : ""}
    </span>
  )
}

const StoreEditToolbar = (props: any) => (
  <ToolbarStyle {...props}>
    <SaveButton />
  </ToolbarStyle>
)

export const StoreEdit = (props: any) => (
  <Edit title={<StoreTitle />} {...props} bulkActionButtons={false}>
    <SimpleFormWidth toolbar={<StoreEditToolbar />}>
      <TwoInput>
        <TextInputCss
          source="storeName"
          label="Store Name"
          required={true}
          validate={required()}
          fullWidth
        />
        <TextInputCss
          source="locationID3"
          label="Location ID"
          required={true}
          validate={required()}
          fullWidth
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="storeStreetAddress"
          label="Street Address"
          required={true}
          validate={required()}
        />
        <TextInputCss fullWidth source="storeCity" label="City" />
      </TwoInput>
      <TwoInput>
        <TextInputCss fullWidth source="storeState" label="State" />
        <TextInputCss fullWidth source="storeZip" label="Zip" />
      </TwoInput>
    </SimpleFormWidth>
  </Edit>
)
