import * as React from "react"
import { Edit, SaveButton } from "react-admin"
import {
  TextInputCss,
  TextInputFullCss,
  SimpleFormWidth,
  ToolbarStyle,
  TwoInput,
} from "../../utils/styled"
import { required } from "../../utils/validate"

const ConsumerEditToolbar = (props: any) => (
  <ToolbarStyle {...props}>
    <SaveButton />
  </ToolbarStyle>
)

const ConsumerTitle = (record: any) => {
  return (
    <span>
      Consumer{" "}
      {record && record.record
        ? `"${record.record.patientFirstName} ${record.record.patientLastName}"`
        : ""}
    </span>
  )
}

export const ConsumerEdit = (props: any) => (
  <Edit title={<ConsumerTitle />} {...props} bulkActionButtons={false}>
    <SimpleFormWidth toolbar={<ConsumerEditToolbar />}>
      {/* <TextInput className="hide" disabled source="id" /> */}
      <TwoInput>
        <TextInputCss
          source="pmsPatientID"
          label=" Patient ID"
          required={true}
          fullWidth
          validate={required()}
        />
        <TextInputCss
          source="patientTitle"
          label="Patient Title"
          required={true}
          fullWidth
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          source="patientFirstName"
          label="First Name"
          required={true}
          fullWidth
          validate={required()}
        />
        <TextInputCss
          source="patientLastName"
          label="Last Name"
          required={true}
          fullWidth
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          source="patientDOB"
          label=" Patient DOB"
          required={true}
          fullWidth
          validate={required()}
        />
        <TextInputCss
          source="patientGender"
          label="Gender"
          required={true}
          fullWidth
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          source="patientHomeNum"
          label="Home Number"
          required={true}
          fullWidth
          validate={required()}
        />
        <TextInputCss
          source="patientWorkNum"
          label="Work Number"
          required={true}
          fullWidth
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          source="patientCellNum"
          label="Cell Number"
          required={true}
          fullWidth
          validate={required()}
        />
        <TextInputCss
          source="patientEmail"
          label="Patient Email"
          required={true}
          fullWidth
          validate={required()}
        />
      </TwoInput>
      <TextInputFullCss
        source="patientAddress1"
        label="Address 1"
        fullWidth
        required={true}
        validate={required()}
      />
      <TextInputFullCss
        source="patientAddress2"
        label="Address 2"
        fullWidth
        required={true}
        validate={required()}
      />
      <TwoInput>
        <TextInputCss
          fullWidth
          source="patientCity"
          label="City"
          required={true}
          validate={required()}
        />
        <TextInputCss
          fullWidth
          source="patientState"
          label="State"
          required={true}
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="patientZip"
          label="Zip"
          required={true}
          validate={required()}
        />
        <TextInputCss
          fullWidth
          source="patientLastVisitOrExamDate"
          label="Last Visit Or ExamDate"
          required={true}
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="providerID"
          label="Provider ID"
          required={true}
          validate={required()}
        />
        <TextInputCss
          fullWidth
          source="locationID"
          label="Location ID"
          required={true}
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="smsPreference"
          label="Preference"
          required={true}
          validate={required()}
        />
        <TextInputCss
          fullWidth
          source="emailPreference"
          label="Email Preference"
          required={true}
          validate={required()}
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="voicePreference"
          label="Voice Preference"
          required={true}
          validate={required()}
        />
        <TextInputCss
          fullWidth
          source="postalPreference"
          label="Postal Preference"
          required={true}
          validate={required()}
        />
      </TwoInput>
      {/* <CustomReferenceInputCss
        fullWidth
        label="Store"
        source="storeID"
        reference="stores"
        allowEmpty={false}
        perPage={0}
      >
        <CustomSelectInput optionText="storeName" />
      </CustomReferenceInputCss> */}
    </SimpleFormWidth>
  </Edit>
)
