import * as React from "react"
import { useCallback } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import {
  useInput,
  FieldTitle,
  useTranslate,
  ChoicesInputProps,
  useChoices,
  warning,
} from "ra-core"

import { ResettableTextField } from "react-admin"
import { InputHelperText } from "react-admin"
import { TextFieldProps } from "@material-ui/core/TextField"

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  emptyValue,
  basePath,
  choices,
  className,
  component,
  crudGetMatching,
  crudGetOne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  initialValue,
  input,
  isRequired,
  label,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  disableValue,
  pagination,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}: any) => rest

const useStyles = makeStyles(
  theme => ({
    input: {
      minWidth: theme.spacing(20),
    },
  }),
  { name: "RaSelectInput" }
)

/**
 * An Input component for a select box, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <SelectInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <SelectInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Select> component
 *
 * You can disable some choices by providing a `disableValue` field which name is `disabled` by default
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', disabled: true },
 * ];
 *
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', not_available: true },
 * ];
 * <SelectInput source="gender" choices={choices} disableValue="not_available" />
 *
 */
const SelectInput: React.FC<
  ChoicesInputProps<TextFieldProps> &
    Omit<TextFieldProps, "label" | "helperText">
> = props => {
  const {
    allowEmpty,
    choices = [],
    classes: classesOverride,
    className,
    disableValue,
    emptyText,
    emptyValue,
    // eslint-disable-next-line react/prop-types
    format,
    // eslint-disable-next-line react/prop-types
    helperText,
    label,
    // eslint-disable-next-line react/prop-types
    onBlur,
    // eslint-disable-next-line react/prop-types
    onChange,
    // eslint-disable-next-line react/prop-types
    onFocus,
    options,
    optionText,
    optionValue,
    // eslint-disable-next-line react/prop-types
    parse,
    resource,
    source,
    translateChoice,
    // eslint-disable-next-line react/prop-types
    validate,
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)

  warning(
    source === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the source prop`
  )

  warning(
    choices === undefined,
    `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the choices prop`
  )

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  })

  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source: source || "",
    validate,
    ...rest,
  })

  const renderEmptyItemOption = useCallback(() => {
    return React.isValidElement(emptyText)
      ? React.cloneElement(emptyText)
      : emptyText === ""
      ? " " // em space, forces the display of an empty line of normal height
      : translate(emptyText, { _: emptyText })
  }, [emptyText, translate])

  const renderMenuItemOption = useCallback(choice => getChoiceText(choice), [
    getChoiceText,
  ])

  return (
    <ResettableTextField
      id={id}
      {...input}
      select
      label={
        label !== "" &&
        label !== false && (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )
      }
      className={`${classes.input} ${className}`}
      clearAlwaysVisible
      // error={!!(touched && error)}
      // helperText={
      //   <InputHelperText
      //     touched={touched || false}
      //     error={error}
      //     helperText={helperText}
      //   />
      // }
      {...options}
      {...sanitizeRestProps(rest)}
    >
      {allowEmpty ? (
        <MenuItem
          value={"all"}
          key="null"
          aria-label={translate("ra.action.clear_input_value")}
          title={translate("ra.action.clear_input_value")}
        >
          {renderEmptyItemOption()}
        </MenuItem>
      ) : null}
      <MenuItem
        value={0}
        key="-1"
        aria-label={translate("ra.action.clear_input_value")}
        title={translate("ra.action.clear_input_value")}
      >
        un-assigned
      </MenuItem>
      {choices.map(choice => (
        <MenuItem
          key={getChoiceValue(choice)}
          value={getChoiceValue(choice)}
          disabled={get(choice, disableValue)}
        >
          {renderMenuItemOption(choice)}
        </MenuItem>
      ))}
    </ResettableTextField>
  )
}

SelectInput.propTypes = {
  allowEmpty: PropTypes.bool,
  emptyText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  emptyValue: PropTypes.any,
  choices: PropTypes.any, //PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resettable: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
}

SelectInput.defaultProps = {
  emptyText: "",
  emptyValue: "",
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  disableValue: "disabled",
}

export default SelectInput
