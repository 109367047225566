import * as React from "react"
import {
  TableCell,
  TableHead,
  TableRow,
  ClickAwayListener,
} from "@material-ui/core"
import { TextFieldUI, TableCellStyle } from "../../utils/styled"
import { HeadCell } from "./store.model"
import { useStoresFilter } from "./useStoresFilter"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from "@material-ui/icons/Search"

export const StoresFilter = () => {
  const {
    changeValueFilter,
    headCells,
    handleClickFilter,
    handleClickAway,
  } = useStoresFilter()
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <ClickAwayListener
            key={`head-${headCell.id}-${headCell.label}`}
            onClickAway={() => handleClickAway(headCell)}
          >
            <TableCellStyle
              align={headCell.label === "Edit" ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
            >
              {headCell.isFilter || headCell.valueSeach !== "" ? (
                <TextFieldUI
                  id={`standard-search-${headCell.id}`}
                  autoFocus
                  placeholder={headCell.label}
                  name={headCell.id.toString()}
                  defaultValue={headCell.valueSeach}
                  onChange={changeValueFilter}
                  type={
                    headCell.label === "Latitude" ||
                    headCell.label === "Longitude"
                      ? "number"
                      : "search"
                  }
                  InputProps={{
                    "aria-label": "description",
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon style={{ fontSize: "1.2rem" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <div
                  className="label-headcell"
                  onClick={() => handleClickFilter(headCell)}
                >
                  {headCell.label}
                </div>
              )}
            </TableCellStyle>
          </ClickAwayListener>
        ))}
      </TableRow>
    </TableHead>
  )
}
