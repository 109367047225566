import React, { useState } from "react"
import { FC } from "react"
import { useDispatch } from "react-redux"
import { refreshView, useNotify } from "ra-core"
import { Dialog, DialogContent, Modal } from "@material-ui/core"
import dataProvider from "../dataProvider"
import { ImportButtonToolBar } from "../utils/styled"
import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp"
import { DropzoneArea } from "material-ui-dropzone"
import GroupAddSharpIcon from "@material-ui/icons/GroupAddSharp"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import { SvgIcon } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ReactComponent as uploadSvg } from "../icons/upload.svg"
interface MyDialogType {
  open: boolean
  handleClose: any
  onFileChange: any
  handleImport: any
}

const uploadIcon = () => {
  return (
    <SvgIcon
      component={uploadSvg}
      viewBox="0 0 600 600"
      style={{
        color: "#aaaaaa",
        // width: "40px",
        // height: "40px",
        fontSize: "25px",
      }}
    />
  )
}

const MyDialog = (props: MyDialogType) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MuiDialogTitle disableTypography>
        <GroupAddSharpIcon
          style={{
            position: "absolute",
            fontSize: "40px",
            left: "30px",
            top: "20px",
          }}
        />
        <Typography
          variant="h6"
          style={{
            paddingTop: "10px",
            paddingLeft: "60px",
            lineHeight: "30px",
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          Import consumers
        </Typography>
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: "1px",
            top: "16px",
            color: "#000",
          }}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <DialogContent>
        <DropzoneArea
          Icon={uploadIcon as any}
          onChange={props.onFileChange.bind(this)}
          showPreviews={false}
          filesLimit={1}
          showAlerts={false}
          dropzoneText="Drag & drop file here or click to select a file"
        />
      </DialogContent>
      {/* <DialogActions style={{ margin: "auto" }}>
        <Button
          onClick={props.handleImport}
          color="primary"
          style={{
            color: "#FFFFFF",
            background: "#0F877E",
            textTransform: "none",
            marginRight: "20px",
            padding: "4px 10px",
          }}
        >
          Ok
        </Button>
        <Button
          onClick={props.handleClose}
          color="primary"
          style={{
            color: "#FFFFFF",
            background: "#000",
            textTransform: "none",
            padding: "4px 10px",
          }}
        >
          Cancel
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  wrapperModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1310,
  },
  contentModal: {
    background: "rgb(49, 49, 49)",
    width: "100%",
    maxWidth: "400px",
    color: "white",
    borderRadius: "4px",
    padding: "30px 50px",
    outline: "none",
    whiteSpace: "pre-line",
    position: "relative",
  },
  closeIcon: {
    position: "absolute",
    right: "20px",
    top: "15px",
    cursor: "pointer",
  },
}))

const ImportConsumersButton: FC = ({ ...rest }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [isNotification, setIsNotification] = useState(false)
  const [message, setMessage] = useState<JSX.Element>()

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
    setUploadFile(null)
  }

  const handleClose = () => {
    setOpen(false)
    setUploadFile(null)
  }

  const onFileChange = async (files: any) => {
    if (files && files.length > 0) {
      await handleImport(files[0])
    }
  }

  const handleNotification = (element: JSX.Element) => {
    setMessage(element)
    setIsNotification(true)
  }

  const notify = useNotify()

  const handleImport = async (file: any) => {
    try {
      if (!file) {
        notify("Please choose import file", "warning")
        return
      }

      const formData = new FormData()
      // Update the formData object
      formData.append("File", file as any)
      // Request made to the backend api
      // Send formData object
      const result: any = await dataProvider.import("consumer", formData)
      if (result.status === 200) {
        const message = (
          <>
            Imported consumers successfully: <br />
            Total consumers were imported: {result.body.totalConsumerImported}
            <br />
            Total consumers were not imported:{" "}
            {result.body.totalConsumerNotImported} <br />
            {result.body.messageConsumerNotImported} <br />
            Total store assignments were done:{" "}
            {result.body.totalStoreAssignment} <br />
            Total consumers assignments to store:{" "}
            {result.body.totalConsumerAssignedToStores}
          </>
        )

        handleNotification(message)
      } else {
        handleNotification(result.body)
      }
    } catch (ex) {
      notify(
        "Error:imported consumers failed, please check format of CSV file",
        "error"
      )
    }
    dispatch(refreshView())
  }

  return (
    <>
      <ImportButtonToolBar
        onClick={handleClickOpen}
        color="default"
        startIcon={<AddCircleOutlineSharpIcon />}
      >
        Import
      </ImportButtonToolBar>
      <MyDialog
        open={open}
        handleClose={handleClose}
        onFileChange={onFileChange}
        handleImport={handleImport}
      />
      <Modal
        open={isNotification}
        className={classes.wrapperModal}
        onClose={() => setIsNotification(false)}
      >
        <div className={classes.contentModal}>
          <span
            className={classes.closeIcon}
            onClick={() => setIsNotification(false)}
          >
            &#x2715;
          </span>
          {message}
        </div>
      </Modal>
    </>
  )
}

export default ImportConsumersButton
