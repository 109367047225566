import * as React from "react"
import { ToolBar, ButtonToolBar } from "../../utils/styled"

import ImportConsumersButton from "../ImportConsumersButton"
import ExportConsumersButton from "../ExportConsumersButton"
import { useListContext } from "react-admin"

export const EnhancedTableToolbar = () => {
  const { filterValues } = useListContext()

  return (
    <ToolBar>
      <ImportConsumersButton />
      <ExportConsumersButton filter={filterValues} />
    </ToolBar>
  )
}
