import * as React from "react"
import { ToolBar, ButtonToolBar } from "../../utils/styled"

import ImportStoresButton from "../ImportStoresButton"
import ExportStoresButton from "../ExportStoresButton"
import { useListContext } from "react-admin"

export const EnhancedTableToolbar = () => {
  const { filterValues } = useListContext()

  return (
    <ToolBar>
      <ImportStoresButton />
      <ExportStoresButton filter={filterValues} />
    </ToolBar>
  )
}
