import React, { useEffect } from "react"
import { useCallback } from "react"
import { useListContext } from "react-admin"

import { HeadCell } from "./store.model"

export const useStoresFilter = () => {
  const { setFilters, displayedFilters, filterValues } = useListContext()

  const [headCells, setHeadCells] = React.useState<HeadCell[]>([
    {
      id: "LocationID3",
      sort: true,
      disablePadding: true,
      label: "Location ID",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreName",
      sort: false,
      disablePadding: false,
      label: "Name",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreStreetAddress",
      sort: false,
      disablePadding: false,
      label: "Street Address",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreCity",
      sort: false,
      disablePadding: false,
      label: "City",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreState",
      sort: false,
      disablePadding: false,
      label: "State",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreZip",
      sort: false,
      disablePadding: false,
      label: "Zip",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreLatitude",
      sort: false,
      disablePadding: false,
      label: "Latitude",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "StoreLongitude",
      sort: false,
      disablePadding: false,
      label: "Longitude",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: false,
    },
    {
      id: "TotalConsumers",
      sort: false,
      disablePadding: false,
      label: "Consumers",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: true,
    },
    {
      id: "",
      sort: false,
      disablePadding: false,
      label: "",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: true,
    },
    {
      id: "",
      sort: false,
      disablePadding: false,
      label: "Edit",
      isFilter: false,
      valueSeach: "",
      isDisabledFilter: true,
    },
  ])

  const changeValueFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target

      setFilters &&
        setFilters({ ...filterValues, [name]: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  const handleClickFilter = useCallback(
    (data: HeadCell) => {
      if (data.isDisabledFilter) {
        return
      }

      const heads: any = [...headCells].map((head: HeadCell) => {
        if (head.id === data.id.toString()) {
          head.isFilter = !head.isFilter
        }
        return head
      })
      setHeadCells(heads)
    },
    [headCells]
  )
  const handleClickAway = useCallback(
    (data: HeadCell) => {
      if (data.isDisabledFilter || !data.isFilter) {
        return
      }
      const heads: any = [...headCells].map((head: HeadCell) => {
        if (head.id === data.id.toString() && !data.valueSeach) {
          head.isFilter = !head.isFilter
        }
        return head
      })
      setHeadCells(heads)
    },
    [headCells]
  )

  useEffect(() => {
    setHeadCells(headCells => {
      const updateHeadCells = headCells.map(headCell => ({
        ...headCell,
        valueSeach: filterValues[headCell.id] || "",
      }))
      return updateHeadCells
    })
  }, [filterValues])

  return {
    headCells,
    changeValueFilter,
    handleClickFilter,
    setFilters,
    handleClickAway,
  }
}
