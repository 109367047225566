import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { showNotification } from "react-admin"
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp"
import dataProvider from "../dataProvider"
import { ExportButtonToolBar } from "../utils/styled"
import { ButtonLoading } from "../common/components"
interface ExportStoresButtonProps {
  record?: any
  showNotification?: any
  filter?: any
}

const ExportStoresButton: React.FC<ExportStoresButtonProps> = (
  props: ExportStoresButtonProps
) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleExport = async () => {
    const { showNotification, filter } = props
    try {
      setIsLoading(true)
      const result = await dataProvider.exportStores(filter)
      const data = result.data
      const fakeLink = document.createElement("a")
      fakeLink.style.display = "none"
      document.body.appendChild(fakeLink)
      const blob = new Blob([data], { type: "text/csv" })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `stores.csv`)
      } else {
        fakeLink.setAttribute("href", URL.createObjectURL(blob))
        fakeLink.setAttribute("download", `stores.csv`)
        fakeLink.click()
      }
      setIsLoading(false)
    } catch (ex) {
      showNotification("Error:export stores failed", "error")
    }
  }

  return (
    <>
      <ButtonLoading isLoading={isLoading} cssStyle={{ marginRight: "2rem" }}>
        <ExportButtonToolBar
          color="default"
          onClick={handleExport}
          startIcon={<GetAppSharpIcon style={{ color: "#fff" }} />}
        >
          Export
        </ExportButtonToolBar>
      </ButtonLoading>
    </>
  )
}

export default connect(null, {
  showNotification,
  // push,
})(ExportStoresButton)
