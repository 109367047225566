/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react"
import { Dialog, DialogContent, Button } from "@material-ui/core"
import dataProvider from "../../dataProvider"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { formatDate } from "../../utils/utils"
import CircularProgress from "@material-ui/core/CircularProgress"
interface MyDialogTypeProps {
  open: boolean
  record: any
  list: any
  handleClose: any
  handleMatch: any
  handleNotMatch: any
  isLoading: boolean
}
interface identityItemType {
  record: any
  title: string
  extraTitle?: string
  isMain: boolean
  handleMatch?: any
  handleNotMatch?: any
  isLoading: boolean
}

const IdentityItemDetail = (props: any) => {
  const { record } = props
  return (
    <table>
      <tr className="odd">
        <td>First Name</td>
        <td>Last Name</td>
      </tr>
      <tr className="even">
        <td>{record.patientFirstName}</td>
        <td>{record.patientLastName}</td>
      </tr>
      <tr className="odd">
        <td>Date Of Birth</td>
        <td>Gender</td>
      </tr>
      <tr className="even">
        <td>{formatDate(record.patientDOB)}</td>
        <td>{record.patientGender}</td>
      </tr>
      <tr className="odd">
        <td>Address 1</td>
        <td>Address 2</td>
      </tr>
      <tr className="even">
        <td>{record.patientAddress1}</td>
        <td>{record.patientAddress2}</td>
      </tr>
      <tr className="odd">
        <td>Email</td>
        <td>City</td>
      </tr>
      <tr className="even">
        <td className="email">{record.patientEmail}</td>
        <td>{record.patientCity}</td>
      </tr>
      <tr className="odd">
        <td>State</td>
        <td>Zip/Potal</td>
      </tr>
      <tr className="even">
        <td>{record.patientState}</td>
        <td>{record.patientZip}</td>
      </tr>
      <tr className="odd">
        <td>Home Number</td>
        <td>Mobile Phone</td>
      </tr>
      <tr className="even">
        <td>{record.patientHomeNum}</td>
        <td>{record.patientCellNum}</td>
      </tr>
    </table>
  )
}

const IdentityItem = (props: identityItemType) => {
  return (
    <div className="identity-item">
      <div className="identity-item-title">
        <label
          htmlFor={`checkbox_${props.record.id}`}
          style={{ cursor: "pointer" }}
        >
          {props.title}
        </label>
        {/* {props.extraTitle && <span>{props.extraTitle}</span>} */}
      </div>
      <div className="identity-item-detail">
        <IdentityItemDetail record={props.record} />
        {!props.isMain && (
          <React.Fragment>
            <Button
              onClick={() => {
                props.handleMatch(props.record)
              }}
              color="primary"
              style={{
                color: "#FFFFFF",
                background: props.isLoading ? "#dddddd" : "#3da5d9",
                textTransform: "none",
                marginRight: "20px",
                padding: "5px 15px",
                marginTop: "20px",
                fontSize: "0.85em",
              }}
              disabled={props.isLoading}
            >
              Yes, it’s a match
            </Button>
            <Button
              onClick={() => {
                props.handleNotMatch(props.record)
              }}
              color="primary"
              style={{
                color: "#FFFFFF",
                background: props.isLoading ? "#dddddd" : "#dd3545",
                textTransform: "none",
                padding: "5px 15px",
                marginTop: "20px",
                fontSize: "0.85em",
              }}
              disabled={props.isLoading}
            >
              No, not a match
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const ManageIdentityModal: React.FC<MyDialogTypeProps> = (
  props: MyDialogTypeProps
) => {
  if (!props.record) {
    return null
  }
  const record = props.record
  const list: [any] = props.list
  if (record === null || list.length <= 0) {
    return null
  }

  const handleClose = () => {
    props.handleClose()
  }
  // const handleMatch = (record: any) => {
  //   props.handleMatch(record)
  // }
  // const handleNotMatch = (record: any) => {
  //   props.handleNotMatch(record)
  // }
  return (
    <Dialog
      maxWidth="xl"
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{ width: "92%", margin: "auto" }}>
        {props.isLoading && <CircularProgress />}
        <h4>Do these records represent the same person?</h4>
        <IconButton
          aria-label="close"
          style={{
            position: "absolute",
            right: "1px",
            top: "16px",
            color: "#000",
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {/* <p style={{ fontSize: "0.9em", marginTop: "0px" }}>
          ARMS found existing patients in your PMS that might match the patient
          you selected. Please compare the details below, and if you are
          confident that one of the suggestions is truly the same person, click
          the blue <b>Confirm Match</b> button to merge the records. If you're
          not sure, or it none of the suggestions are right click the red
          <b> None of These Are Matches</b> button
        </p> */}
        <div className="identity-table">
          <div className="identity-table-record">
            <IdentityItem
              record={record}
              title="Shopko reference consumer record"
              isMain={true}
              isLoading={props.isLoading}
            />
          </div>
          <div className="identity-table-list">
            {list.map((item, index) => (
              <IdentityItem
                key={`list_${item.id}`}
                record={item}
                title={"Shopko consumer record"}
                extraTitle={`${index + 1}/${list.length}`}
                isMain={false}
                handleMatch={props.handleMatch}
                handleNotMatch={props.handleNotMatch}
                isLoading={props.isLoading}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default ManageIdentityModal
