// in src/users.js
import * as React from "react"
import { Pagination, ListBase, Loading } from "react-admin"
import { Table, TableBody, TableRow } from "@material-ui/core"
import {
  TableContainerStyle,
  PaperStyle,
  TableCellStyle,
} from "../../utils/styled"
import { useConsumerList } from "./useConsumerList"
import { defaultfilter } from "./useConsumerFilter"
import { EnhancedTableToolbar } from "./EnhancedTableToolbar"
import { ConsumerFilter } from "./ConsumerFilter"
import { resolveObject } from "../../utils/object"
import ManageIdentityModal from "./ManageIdentityModal"
import { useState } from "react"
import EditIcon from "@material-ui/icons/Edit"
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser"
import dataProvider from "../../dataProvider"
import { refreshView, useNotify } from "ra-core"
import { useDispatch } from "react-redux"
import { DataType } from "./consumers.model"

export const ConsumerListBase = (props: any) => {
  const { dataConsumer, consumerContext, redirectDetails } = useConsumerList()
  const [openIdentityModal, setOpenIdentityModal] = useState(false)
  const [curRecord, setCurRecord] = useState({})
  const [curList, setCurList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingIdentityModal, setIsLoadingIdentityModal] = useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const openIMModal = async (row: any) => {
    try {
      setIsLoading(true)
      const result: any = await dataProvider.getIdentities(row.id)
      if (result.data.length === 0) {
        notify("No data for verification.", "warning")
        setIsLoading(false)
        dispatch(refreshView())
        return
      }
      setCurRecord(row)
      setCurList(result.data)
      setOpenIdentityModal(true)
    } catch (ex) {
      notify("Error:Load data for IM error.", "error")
    }
    setIsLoading(false)
    // redirectDetails(row.id)
  }
  const handleMatch = async (record: any) => {
    try {
      setIsLoadingIdentityModal(true)
      await dataProvider.matchIdentity(record.id)
      const list = [...curList]
      for (let index = list.length - 1; index >= 0; index--) {
        if ((list[index] as any).id === record.id) {
          list.splice(index, 1)
          break
        }
      }
      // update consumer.IS_HUMAN_VERIFICATION_NEEDED
      if (list.length === 0) {
        await dataProvider.updateConsumerHumanVerificationFlag(
          (curRecord as any).id
        )
        dispatch(refreshView())
        setOpenIdentityModal(false)
      }
      setCurList(list as any)
    } catch (ex) {
      notify("Error:Match consumer error", "error")
    }
    setIsLoadingIdentityModal(false)
  }
  const handleNotMatch = async (record: any) => {
    try {
      setIsLoadingIdentityModal(true)
      await dataProvider.notMatchIdentity((curRecord as any).id, record.id)
      const list = [...curList]
      for (let index = list.length - 1; index >= 0; index--) {
        if ((list[index] as any).id === record.id) {
          list.splice(index, 1)
          break
        }
      }
      if (list.length === 0) {
        dispatch(refreshView())
        setOpenIdentityModal(false)
      }
      setCurList(list as any)
    } catch (ex) {}
    setIsLoadingIdentityModal(false)
  }
  return (
    <React.Fragment>
      <PaperStyle>
        <EnhancedTableToolbar />
        <TableContainerStyle>
          <Table stickyHeader>
            <ConsumerFilter />
            <TableBody>
              {dataConsumer.map(row => (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id.toString()}
                  // onClick={() => recordClick(row)}
                >
                  {defaultfilter.map(
                    value =>
                      !value.isNotRender && (
                        <TableCellStyle key={value.id} align="center">
                          {value.dataType == DataType.Boolean
                            ? row[value.id] + " "
                            : resolveObject(value.id.toString(), row)}{" "}
                        </TableCellStyle>
                      )
                  )}
                  <TableCellStyle align="center">
                    <div onClick={() => redirectDetails(row.id)}>
                      <EditIcon style={{ color: "#0F877E" }} />
                    </div>
                  </TableCellStyle>
                  <TableCellStyle align="center">
                    {row.iS_HUMAN_VERIFICATION_NEEDED && (
                      <div onClick={() => openIMModal(row)}>
                        <OpenInBrowserIcon
                          style={{ color: "#0F877E", fontSize: "2em" }}
                        />
                      </div>
                    )}
                  </TableCellStyle>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyle>
        <Pagination rowsPerPageOptions={[consumerContext.perPage]} />
      </PaperStyle>
      <ManageIdentityModal
        open={openIdentityModal}
        isLoading={isLoadingIdentityModal}
        record={curRecord}
        list={curList}
        handleClose={() => {
          setOpenIdentityModal(false)
        }}
        handleMatch={(record: any) => {
          handleMatch(record)
        }}
        handleNotMatch={(record: any) => {
          handleNotMatch(record)
        }}
      />
      {isLoading && (
        <Loading
          loadingPrimary="Loading"
          loadingSecondary="Loading data for identity modal"
          className="IM-loading"
        />
      )}
    </React.Fragment>
  )
}

export const ConsumerList = ({ children, ...props }: any) => (
  <ListBase {...props} perPage={50}>
    <ConsumerListBase {...props} />
  </ListBase>
)
