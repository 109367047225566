import { fetchUtils } from "react-admin"
import { stringify } from "query-string"
import { Pagination } from "./models/Pagination"
import { Options } from "ra-core/esm/dataProvider/fetch"
import {
  GetListParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  CreateParams,
  DeleteParams,
  DeleteManyParams,
} from "ra-core/esm/types"
import { Record } from "ra-core"
import { getAge } from "./utils/utils"
import { encode } from "querystring"
const apiUrl =
  process.env.REACT_APP_API_SERVER_URL ||
  "https://dev-pcman-api.4patientcare.info/v1"
const httpClient = fetchUtils.fetchJson
const options: Options = {}
options.headers = new Headers()
options.headers.set("Accept", "application/json")
options.headers.set("SessionId", localStorage.getItem("SessionId") || "")
const getDataList = (data: Record[], resource: string): any => {
  if (resource === "stores") {
    data.map((t: Record) => {
      return (t.id = t.storeID)
    })
  }
  if (resource === "consumer") {
    data.map((t: Record) => {
      t.age = getAge(t.patientDOB)
      // t.storeID = t.store ? t.store.storeID : ""
      t.storeName = t.store ? t.store.storeName : ""
      t.locationID3 = t.store ? t.store.locationID3 : ""
      return t
    })
  }
  return data
}

const getData = (data: Record, resource: string, params: any): any => {
  if (resource === "stores") {
    data.id = data.storeID
  }
  if (resource === "consumer") {
    data.id = params.id
  }
  return data
}
const getTotal = (headers: Headers): number => {
  try {
    const pagination: Pagination = JSON.parse(headers.get("X-Pagination") || "")
    return pagination.TotalCount ? pagination.TotalCount : 0
  } catch (ex) {}
  return 0
}

const getCreateURL = (resource: string) => {
  if (resource === "consumer") {
    return `${apiUrl}/${resource}/create/?sessionid=${localStorage.getItem(
      "SessionId"
    )}`
  }
  return `${apiUrl}/${resource}`
}

const getFilterQuery = (resource: string, filter: any) => {
  const entries = Object.entries(filter)
  let query = ""
  entries.forEach(param => {
    if (param[1] !== "all" && param[0] !== "q") {
      if (resource === "stores" && param[0] === "q") {
        query += `&StoreName=${encodeURIComponent(param[1] as any)}`
      } else {
        query += `&${param[0]}=${encodeURIComponent(param[1] as any)}`
      }
    }
  })
  return query
}

const getUpdateURL = (resource: string, params: any) => {
  if (resource === "consumer") {
    return `${apiUrl}/${resource}/${params.id}`
  }
  return `${apiUrl}/${resource}`
}

export default {
  getList: (resource: string, params: GetListParams) => {
    // eslint-disable-next-line prefer-const
    let { page, perPage } = params.pagination
    // const { field, order } = params.sort
    // const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify(params.filter),
    // }
    if (perPage === 1000) {
      perPage = 0
    }
    let query = `?PageNumber=${page}&PageSize=${perPage}`
    query += getFilterQuery(resource, params.filter)
    const url = `${apiUrl}/${resource}${query}`
    return httpClient(url, options).then(({ headers, json }) => ({
      data: getDataList(json, resource),
      total: getTotal(headers), // parseInt(headers.get('x-pagination').split('/').pop(), 10),
    }))
  },

  getOne: (resource: string, params: GetOneParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(
      ({ json }) => ({
        data: getData(json, resource, params),
      })
    ),
  getMany: (resource: string, params: GetManyParams) => {
    if (
      params.ids.lastIndexOf("all") >= 0 ||
      params.ids.lastIndexOf("0") >= 0
    ) {
      return Promise.resolve({ data: [] })
    }
    const query = {
      filter: JSON.stringify({
        id: params.ids,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url, options).then(({ json }) => ({
      data: getDataList(json, resource),
    }))
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url, options).then(({ headers, json }) => ({
      data: getDataList(json, resource),
      total: getTotal(headers), //parseInt(headers.get('content-range').split('/').pop(), 10),
    }))
  },

  update: (resource: string, params: UpdateParams) => {
    const url = getUpdateURL(resource, params)
    return httpClient(url, {
      ...options,
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: getData(json, resource, params) }))
  },

  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      ...options,
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource: string, params: CreateParams) => {
    const url = getCreateURL(resource)
    return httpClient(url, {
      ...options,
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (resource === "stores") {
        const data = getData(json, resource, params.data)
        return {
          data: { ...params.data, id: data.id },
        }
      }
      return {
        data: { ...params.data, id: json.id },
      }
    })
  },

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      ...options,
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: DeleteManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      ...options,
      method: "DELETE",
      body: JSON.stringify(params.ids),
    }).then(({ json }) => ({ data: json }))
  },

  import: (resource: string, formData: any) => {
    return httpClient(`${apiUrl}/${resource}/import`, {
      ...options,
      body: formData,
      method: "POST",
    })
      .then(({ status, json }) => ({ status, body: json }))
      .catch(({ body, status }) => {
        return { body, status }
      })
  },

  assignConsumer: (id: any) => {
    return httpClient(`${apiUrl}/stores/${id}/AssignConsumers`, {
      ...options,
      method: "POST",
    }).then(({ json, status }) => ({ data: json, status }))
  },
  checkAuth: (SessionId: any) => {
    return httpClient(`${apiUrl}/admin/dashboard/check-auth`, {
      ...{
        headers: new Headers({
          Accept: "application/json",
          SessionId,
        }),
      },
      //method: "POST",
    })
      .then(() => true)
      .catch(() => false)
  },
  exportConsumers: (filter: any) => {
    let url = `${apiUrl}/consumer/export`
    const query = getFilterQuery("consumer", filter)
    if (query) {
      url += `?${query}`
    }
    return httpClient(url, options).then(({ body }) => ({ data: body }))
  },
  exportStores: (filter: any) => {
    let url = `${apiUrl}/store/export`
    const query = getFilterQuery("stores", filter)
    if (query) {
      url += `?${query}`
    }
    return httpClient(url, options).then(({ body }) => ({ data: body }))
  },
  getIdentities: (id: string) => {
    const url = `${apiUrl}/formated-consumer-im?consumerId=${id}`
    return httpClient(url, options).then(({ json }) => ({ data: json }))
  },
  matchIdentity: (id: number) => {
    const url = `${apiUrl}/formated-consumer-im/${id}/match`
    return httpClient(url, {
      ...options,
      method: "PATCH",
    }).then(({ json }) => ({ data: json }))
  },
  notMatchIdentity: (consumerId: string, groupId: number) => {
    const url = `${apiUrl}/formated-consumer-im/${groupId}/${consumerId}/not-match`
    return httpClient(url, {
      ...options,
      method: "PATCH",
    }).then(({ json }) => ({ data: json }))
  },
  updateConsumerHumanVerificationFlag: (id: number) => {
    const url = `${apiUrl}/consumer/${id}/update-is-human-verification-needed`
    return httpClient(url, {
      ...options,
      method: "PATCH",
    }).then(({ json }) => ({ data: json }))
  },
}
