import { Identifier } from "react-admin"

export interface IDataConsumerList {
  id: Identifier
  [label: string]: string | number | Record<string, unknown>
}

export enum DataType {
  String,
  Boolean,
}
export interface HeadCell {
  disablePadding: boolean
  id: keyof IDataConsumerList
  label: string
  sort: boolean
  isFilter: boolean
  isDisabledFilter: boolean
  valueSeach: string
  isNotRender: boolean
  dataType?: DataType
}
