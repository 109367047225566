// in src/App.js
import * as React from "react"
import { Admin, Resource } from "react-admin"
import { createBrowserHistory } from "history"
import { Route } from "react-router-dom"
// import Dashboard from "./Dashboard"

import UserIconBase from "@material-ui/icons/Group"
import StoreMallDirectorySharpIconBase from "@material-ui/icons/StoreMallDirectorySharp"

import MyLoginPage from "./login"
import { MyLayout } from "./layout"
import dataProvider from "./dataProvider"
import authProvider from "./authProvider"
import {
  ConsumerList,
  ConsumerEdit,
  ConsumerCreate,
} from "./components/Consumers"
import { StoreList, StoreEdit, StoreCreate } from "./components/Stores"

const StoreMallDirectorySharpIcon = () => (
  <StoreMallDirectorySharpIconBase style={{ color: "white" }} />
)

const UserIcon = () => <UserIconBase style={{ color: "white" }} />

const history = createBrowserHistory()
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const App = () => (
  <Admin
    // dashboard={Dashboard}
    dataProvider={dataProvider}
    authProvider={authProvider}
    history={history}
    title="Shopko"
    layout={MyLayout}
    loginPage={MyLoginPage}
    customRoutes={[
      <Route
        key={"route-login"}
        path="/login-session"
        component={MyLoginPage}
        noLayout
      />,
    ]}
  >
    <Resource
      name="stores"
      icon={StoreMallDirectorySharpIcon}
      list={StoreList}
      create={StoreCreate}
      edit={StoreEdit}
    />
    <Resource
      name="consumer"
      icon={UserIcon}
      list={ConsumerList}
      create={ConsumerCreate}
      edit={ConsumerEdit}
    />
  </Admin>
)
export default App
