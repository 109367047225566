import * as React from "react"
import { /*AppBar,*/ AppBarProps, UserMenu } from "react-admin"
import CustomAppBar from "../components/CustomAppBar"
import Box from "@material-ui/core/Box"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { MyMenu } from "./MyMenu"

const useStyles = makeStyles({
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  toolbar: {
    margin: "0 60px 0 110px",
    height: "100%",
  },
})

const useStylesHeader = makeStyles({
  header: {
    height: "65px",
    paddingTop: "10px",
    backgroundColor: "#0F877E",
    color: "white",
  },
  lineH: {
    borderLeft: "1px soilid red",
  },
})

export type IMyAppBarProps = AppBarProps

export const MyAppBar: React.FC<IMyAppBarProps> = (props: IMyAppBarProps) => {
  const classes = useStyles()
  const stylesHeader = useStylesHeader()
  const { title, logout } = props

  return (
    <Box display="flex" justifyContent="center">
      <CustomAppBar
        {...props}
        className={stylesHeader.header}
        classes={classes}
        userMenu={
          <>
            <UserMenu logout={logout} />
          </>
        }
      >
        <Typography variant="h6" color="inherit" className={classes.title}>
          {title || "SHOPOK"}
        </Typography>
        <MyMenu />
      </CustomAppBar>
    </Box>
  )
}
