import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import {
  AuthenticationResult,
  InteractionType,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser"
import { getB2cConfigExperience } from "./msalConfig"
import axios from "axios"
import Constants from "./constants"
//import Rest from '../../general/rest';

const scopes: string[] = ["openid"]
const authRequest: RedirectRequest = {
  scopes: scopes,
}

export const GetTokenContext = createContext<
  () => Promise<AuthenticationResult | undefined>
>(async () => undefined)

type ChildrenProps = {
  children: ReactNode
}

/**
 * A component inside which nothing will render until a valid AD B2C authentication exists, and which redirects
 * the user to the authentication workflow if they are not authenticated.
 */
const Authwall = (props: ChildrenProps) => {
  const [pca, setPca] = useState<PublicClientApplication | undefined>(undefined)

  const isBrowser = typeof window !== "undefined"
  const getWindowLocation = () => {
    if (isBrowser) {
      return window.location.search
    } else {
      return ""
    }
  }
  const queryParams = new URLSearchParams(getWindowLocation())
  const authExperience = queryParams.get("authExperience") ?? "default"

  //Fetch the AD B2C configuration details and create the public client application object
  useEffect(() => {
    const getConfig = async () => {
      const b2cConfig = getB2cConfigExperience(authExperience)

      return b2cConfig
    }
    const fetchConfig = async () => {
      const config = await getConfig()
      if (config) {
        const newPca = new PublicClientApplication(config)
        setPca(newPca)
      }
    }
    fetchConfig()
  }, [])

  return pca === undefined ? (
    <></>
  ) : (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <MsalContainer>{props.children}</MsalContainer>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

/**
 * Inner component for authwall, enabling the use of the useMsal hook.
 */
const MsalContainer = (props: ChildrenProps) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  //The token fetch method, which will go into a context allowing any part of the app to use
  //the jwt token
  const fetchToken = useCallback(async () => {
    let ret = undefined
    if (account) {
      const token = await instance.acquireTokenSilent({
        scopes: scopes,
        account: account,
      })
      if (token) {
        ret = token
      }
    }
    return ret
  }, [instance, account])

  //Initialize the rest client with the jwt token so that the token is automatically passed to
  //all API calls
  useEffect(() => {
    const asyncPortionOfUseEffect = async () => {
      const b2cToken = (await fetchToken())?.idToken
      localStorage.setItem(Constants.currUserKey, b2cToken || "")
      //setstate({...state, user});
      console.log(`useEffect [account]: b2cToken ${JSON.stringify(b2cToken)} `)
    }
    asyncPortionOfUseEffect()
  }, [fetchToken])

  return (
    <GetTokenContext.Provider value={fetchToken}>
      {props.children}
    </GetTokenContext.Provider>
  )
}

export default Authwall
