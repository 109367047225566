import React from "react"
import { Create } from "react-admin"
import { TextInputCss, SimpleFormWidth, TwoInput } from "../../utils/styled"
import { required } from "../../utils/validate"

export const StoreCreate = (props: any) => (
  <Create {...props}>
    <SimpleFormWidth>
      <TwoInput>
        <TextInputCss
          source="storeName"
          label="Store Name"
          required={true}
          validate={required()}
          fullWidth
        />
        <TextInputCss
          source="locationID3"
          label="Location ID"
          required={true}
          validate={required()}
          fullWidth
        />
      </TwoInput>
      <TwoInput>
        <TextInputCss
          fullWidth
          source="storeStreetAddress"
          label="Street Address"
          required={true}
          validate={required()}
        />
        <TextInputCss fullWidth source="storeCity" label="City" />
      </TwoInput>
      <TwoInput>
        <TextInputCss fullWidth source="storeState" label="State" />
        <TextInputCss fullWidth source="storeZip" label="Zip" />
      </TwoInput>
    </SimpleFormWidth>
  </Create>
)
