import React, { PropsWithChildren } from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

interface IButtonLoadingProps {
  isLoading: boolean
  cssName?: string
  cssStyle?: React.CSSProperties
}

export const ButtonLoading: React.FC<IButtonLoadingProps> = (
  props: PropsWithChildren<IButtonLoadingProps>
) => {
  return props.isLoading ? (
    <CircularProgress className={props.cssName} style={props.cssStyle} />
  ) : (
    <>{props.children}</>
  )
}
