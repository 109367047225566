// in src/users.js
import * as React from "react"
import { Pagination, ListBase } from "react-admin"
import { Table, TableBody, TableRow } from "@material-ui/core"
import {
  TableContainerStyle,
  PaperStyle,
  TableCellStyle,
} from "../../utils/styled"
import { useStoresList } from "./useStoresList"
import { EnhancedTableToolbar } from "./EnhancedTableToolbar"
import { StoresFilter } from "./StoresFilter"
import AssignButton from "../AssignButton"
import EditIcon from "@material-ui/icons/Edit"

export const StoreListBase = () => {
  const { dataStores, /*storeContext,*/ redirectDetails } = useStoresList()

  return (
    <React.Fragment>
      <PaperStyle>
        <EnhancedTableToolbar />
        <TableContainerStyle>
          <Table stickyHeader>
            <StoresFilter />
            <TableBody>
              {dataStores.map(row => (
                <TableRow hover tabIndex={-1} key={row.id.toString()}>
                  <TableCellStyle align="left">
                    {row.locationID3}
                  </TableCellStyle>
                  <TableCellStyle align="left">{row.storeName}</TableCellStyle>
                  <TableCellStyle align="left">
                    {row.storeStreetAddress}
                  </TableCellStyle>
                  <TableCellStyle align="left">{row.storeCity}</TableCellStyle>
                  <TableCellStyle align="left">{row.storeState}</TableCellStyle>
                  <TableCellStyle align="left">{row.storeZip}</TableCellStyle>
                  <TableCellStyle align="left">
                    {row.storeLatitude}
                  </TableCellStyle>
                  <TableCellStyle align="left">
                    {row.storeLongitude}
                  </TableCellStyle>
                  <TableCellStyle align="left">
                    {row.totalConsumers}
                  </TableCellStyle>
                  <TableCellStyle align="left">
                    <AssignButton label="Assign" record={row} />
                  </TableCellStyle>
                  <TableCellStyle align="center">
                    <div onClick={() => redirectDetails(row.id)}>
                      <EditIcon style={{ color: "#0F877E" }} />
                    </div>
                  </TableCellStyle>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyle>
        <Pagination className="pagination" rowsPerPageOptions={[50]} />
      </PaperStyle>
    </React.Fragment>
  )
}

export const StoreList = ({ children, ...props }: any) => (
  <ListBase {...props} perPage={50}>
    <StoreListBase {...props} />
  </ListBase>
)
